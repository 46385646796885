import React, { useEffect, useRef } from "react"
import { graphql } from "gatsby"

import Index from "../components/layout"
import CategorySelector from "../components/articles/blogCategorySelector.js";
import Articles from "../components/articles/articles";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Pen from '../images/pen-tool.svg'

export const query = graphql`
  query {
    articles: allStrapiArticle(filter: {active: {eq: true}, highlighted_articles: {elemMatch: {name: {eq: "Highlighted articles"}}}}, sort: {order: DESC, fields: published_at}) {
      edges {
        node {
          title
          slug
          published_at
          content
          blog_category{
            name
            slug
            icon {
              publicURL
            }
          }
          image {
            childImageSharp {
                fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
          }
        }
      }
    }
    categories: allStrapiBlogCategories(sort: {order: ASC, fields: Order}) {
      edges {
        node {
          name
          slug
          page_title
          icon {
            publicURL
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {

  const title = 'Highlighted Articles';
  const description = "Check out the latest articles from our blog";

  const articles = data.articles.edges;
  const categories = data.categories.edges;

  let blogLatestTitle = useRef(null);
  let blogLatestSubTitle = useRef(null);

  useEffect(() => {
    gsap.from(blogLatestTitle, 0.4, {
      opacity: 0,
      delay: 0.1
    });
    gsap.from(blogLatestSubTitle, 0.4, {
      opacity: 0,
      delay: 0.2
    });
  }, [blogLatestTitle, blogLatestSubTitle]);

  return (
      <Index
          title={title}
          description={description}
      >
          <div className="regular-section blog-category">
              <div ref={el => (blogLatestSubTitle = el)} className="category-title">
                  <span>
                    <img src={Pen} alt="blog" />
                    <h2>Highlighted</h2>
                  </span>
              </div>
              <CategorySelector categories={categories}/>
              <Articles articles={articles} />
          </div>
      </Index>
  )
}

export default Blog
