import React from "react"
import TransitionLink from "gatsby-plugin-transition-link";
import {Power2, TweenLite} from "gsap";
import scrollTo from "gatsby-plugin-smoothscroll";


const categorySelector = ({ categories }) => {

    const TRANSITION_LENGTH = .5

    const exitTransitionUp = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPageUp(),
    }

    const entryTransition = {
        delay: TRANSITION_LENGTH,
        trigger: () => animateEntryPage(),
    }

    function animateExitPageUp() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateEntryPage() {

        let html = document.querySelector("html");

        setTimeout(() => {
            html.classList.remove("smoothScrollOff")
        }, 120);
    }

    return (
        <div className="blog-categ-content">
            {categories.map((category, i) => {
                return (
                    <TransitionLink
                        className="single-blog-categ"
                        to={`/blog/${category.node.slug}`}
                        exit={exitTransitionUp}
                        entry={entryTransition}
                    >
                        <div className="single-categ-content">
                            <img src={category.node.icon.publicURL} alt={category.node.name} />
                            <p>{category.node.name}</p>
                        </div>
                    </TransitionLink>
                )
            })}
        </div>
    )
}

export default categorySelector
